import toTitleCase from "../utils/toTitleCase"

export const getTitle = (type, city, area) => {
  city = city ? toTitleCase(city) : city
  area = area ? toTitleCase(area) : area

  return type && city && area
    ? `${type.charAt(0).toUpperCase() + type.slice(1)} ${area} ${city} - Vapaat vuokrattavat tilat`
    : type && city
    ? `${type.charAt(0).toUpperCase() + type.slice(1)} ${city} - Vapaat vuokrattavat tilat`
    : type
    ? `Toimitilahaku - Kaikki vapaat ${type}`
    : null
}

export const getMetaDesc = (type, city, area, numberResults) => {
  city = city ? toTitleCase(city) : city
  area = area ? toTitleCase(area) : area

  return type && city && area
    ? `Katso kaikki vapaat ${type}  alueella ${area}, ${city}. Katso tarkemmat tiedot vapaista kohteista, joita tällä hetkellä ${numberResults} kpl alueella ${area}.`
    : type && city
    ? `Vapaat toimitilat alueelta ${city}. Laaja kohdevalikoima kaikkiin tarpeisiin ja hintaluokkiin. Helppo haku & tarkat tiedot. Katso vapaat ${type}!`
    : type
    ? `Löydät kaikki vapaat ${type} Newsec toimitilahausta. Laaja kohdevalikoima kaikkiin tarpeisiin ja hintaluokkiin. Helppo haku & tarkat tiedot. Katso vapaat ${type}!`
    : null
}

export const getIntroText = (type, city, area) => {
  city = city ? toTitleCase(city) : city
  area = area ? toTitleCase(area) : area

  const location = area ? `${area}` : city ? `${city}` : null
  if (!location) return null

  switch (type) {
    case "toimistot":
      return `Oletko aikeissa vuokrata yrityksellesi toimiston kohteesta ${location}? Täältä se löytyy! Vapaita toimistotiloja on tarjolla niin ydinkeskustasta kuin vähän kauempaakin – kaikki erinomaisten liikenneyhteyksien varrelta.`
    case "liiketilat":
      return `Etsitkö yrityksellesi vuokrattavaa liiketilaa alueelta ${location}? Tutustu tarjontaamme ja löydä yrityksellesi juuri sopiva vaihtoehto! Toimitilahaustamme löydät vapaat liiketilat kohteesta ${location}. Valitse sinun tarpeisiisi parhaiten sopiva liiketila ja ota yhteyttä vuokrauksen ammattilaisiimme.`
    case "varastot":
      return `Etsitkö yrityksellesi vuokrattavaa varastoa tai logistiikkatilaa alueelta ${location}? Tutustu tarjontaamme ja löydä yrityksellesi juuri sopiva vaihtoehto! Toimitilahaustamme löydät vapaat varastot ja logistiikkatilat kohteesta ${location}. Valitse sinun tarpeisiisi parhaiten sopiva varastotila ja ota yhteyttä vuokrauksen ammattilaisiimme.`
    case "tuotantotilat":
      return `Etsitkö yrityksellesi vuokrattavaa tuotantotilaa alueelta ${location}? Tutustu tarjontaamme ja löydä yrityksellesi juuri sopiva vaihtoehto! Toimitilahaustamme löydät vapaat tuotantotilat kohteesta ${location}. Valitse sinun tarpeisiisi parhaiten sopiva varastotila ja ota yhteyttä vuokrauksen ammattilaisiimme.`
    default:
      return `Etsitkö yrityksellesi vapaata toimitilaa kohteesta ${location}? Toimitilahakukoneesta löydät vuokrattavat toimistot, liiketilat ja varastot alueelta ${location}! Satoja vapaita toimitiloja, joista voit valita sinun tarpeisiisi parhaiten sopivan vaihtoehdon. Myös vuokrauksen ammattilaiset ovat apunasi.`
  }
}

export const getLeadText = (type, city, area) => {
  const location = area ? `${area}` : city ? `${city}` : null
  if (!location) return null

  switch (type) {
    case "toimistot":
      return `Oletko aikeissa vuokrata yrityksellesi toimiston kohteesta ${location}? Täältä se löytyy! Vapaita toimistotiloja on tarjolla niin ydinkeskustasta kuin vähän kauempaakin – kaikki erinomaisten liikenneyhteyksien varrelta.`
    case "liiketilat":
      return `Etsitkö yrityksellesi vuokrattavaa liiketilaa alueelta ${location}? Tutustu tarjontaamme ja löydä yrityksellesi juuri sopiva vaihtoehto! Toimitilahaustamme löydät vapaat liiketilat kohteesta ${location}. Valitse sinun tarpeisiisi parhaiten sopiva liiketila ja ota yhteyttä vuokrauksen ammattilaisiimme.`
    case "varastot":
      return `Etsitkö yrityksellesi vuokrattavaa varastoa tai logistiikkatilaa alueelta ${location}? Tutustu tarjontaamme ja löydä yrityksellesi juuri sopiva vaihtoehto! Toimitilahaustamme löydät vapaat varastot ja logistiikkatilat kohteesta ${location}. Valitse sinun tarpeisiisi parhaiten sopiva varastotila ja ota yhteyttä vuokrauksen ammattilaisiimme.`
    case "tuotantotilat":
      return `Etsitkö yrityksellesi vuokrattavaa tuotantotilaa alueelta ${location}? Tutustu tarjontaamme ja löydä yrityksellesi juuri sopiva vaihtoehto! Toimitilahaustamme löydät vapaat tuotantotilat kohteesta ${location}. Valitse sinun tarpeisiisi parhaiten sopiva tuotantotila ja ota yhteyttä vuokrauksen ammattilaisiimme.`
    default:
      return `Etsitkö yrityksellesi vapaata toimitilaa kohteesta ${location}? Toimitilahakukoneesta löydät vuokrattavat toimistot, liiketilat ja varastot alueelta ${location}! Satoja vapaita toimitiloja, joista voit valita sinun tarpeisiisi parhaiten sopivan vaihtoehdon. Myös vuokrauksen ammattilaiset ovat apunasi.`
  }
}
