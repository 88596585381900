import React, { useContext } from "react"
import { graphql } from "gatsby"
import { Router } from "@reach/router"
import * as R from "ramda"

import SearchContext from "../contexts/SearchContext"
import queryString from 'query-string'

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"

import { Container, Typography, Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/SEO"
import Hero from "../components/page/Hero"

import SearchBox from "../components/page/search/SearchBox"
import Breadcrumb from "../components/navigation/Breadcrumb"
import Results from "../components/page/search/Results"
import Highlight from "../components/page/main/Highlight"
import References from "../components/page/main/References"
import Profile from "../components/page/main/Profile"
import FeaturedContent from "../components/page/main/FeaturedContent"

import { getTitle, getMetaDesc, getIntroText } from "../data/seo-defaults.js"

import colors from "../components/theme/colors"

import { getFiltersFromURL } from "../utils/urlSearchFilters"
import toTitleCase from "../utils/toTitleCase"

/*  This template handles all search result/landing pages:
    - For landing pages (which are SSR results) the content will be 
      applied via the pageContext when the template is rendered by Gatsby build.
      The pages that are SSR are determined via data input in Gatsby node.

    - For dynamic searchs (criteria set by the user) - the content will be determined 
      by the URL path and use the Reach router to update and parse the tempalte URLs.
      The dynamic search pages are created for the base URL type (e.g. /toimitilat/) 
      and parse everything else in the URL to make the search criteria.

    See notes in gatsby-node.js on how the SSR & Reach router paths are configured for the app. 

    Other than those aspects, the functionality of the landing/search pages is identical as 
    this template handles the filtering of search results. Plus some other
*/

const useStyles = makeStyles(theme => ({
  introDescription: {
    color: colors.base.white,
    margin: "1rem auto 1.5rem",
    textAlign: "center",
    maxWidth: "800px",
  },
  resultsContainer: {
    margin: "1rem auto 3rem",
  },
  contentContainer: {
    background: colors.grey["-"],
    paddingTop: "1rem",
    paddingBottom: "2.5rem",
  },
  contentHeadings: {
    marginTop: "1em",
    marginBottom: "0.5em",
    textTransform: "uppercase",
  },
  contentText: {
    marginBottom: "1.25em",
  },
}))

const SearchPage = ({ data, pageContext, location }) => {
  const { dynamic } = pageContext
  let { type, city, area } = pageContext

  const currentUrl = typeof window !== 'undefined' ? window.location.pathname : '';

  const { urlPathContext, useExistingFilters, queryParams } = getFiltersFromURL(
    location
  )

  const searchContext = useContext(SearchContext)
  const {
    setResultPage,
  } = searchContext
  if(typeof window !== 'undefined'){
    // Save page attribute from URL to search context resultPage on client
    const pageAttribute = Number(R.path(['page'], queryString.parse(R.path(["location", "search"], window))));
    pageAttribute ? setResultPage(pageAttribute) : setResultPage(1);
  }

  // If page is not SSR, take the type, city, area from the path
  if (dynamic) {
    type = urlPathContext.type
    city = urlPathContext.city
    area = urlPathContext.area
  }

  /*
  Next decide how the filters for the paghe should be defined:

  - If the path has a query parameter "f=true" then use the existing filters in the context 
  as the user is navigating the site whilst searching. In the case of this page rendering, do 
  nothing as the filters are already saved in the SearchContext.

  - If this query parameter is not set, then the user has clicked or followed a directly URL link 
  to the search page, so reset the filters based on the page path.

  FYI: There is a potential here to have more complex behaviour on how the page interacts with existing 
  filters & with additional query parameters, but that is for future design :)

  */
  if (!useExistingFilters) {
    // Grab any extra query params for the filter then
    // force refresh and updated of filters
    searchContext.updateFilters(
      { type, city, area },
      {
        ...(queryParams.smin && { sizeMin: queryParams.smin }),
        ...(queryParams.smax && { sizeMax: queryParams.smax }),
        ...((queryParams.wmin, queryParams.wmax)
          ? { workspaces: { min: queryParams.wmin, max: queryParams.wmax } }
          : queryParams.wmin && !queryParams.wmax
          ? { workspaces: { min: queryParams.wmin } }
          : !queryParams.wmin && queryParams.wmax
          ? { workspaces: { max: queryParams.wmax } }
          : {}),
      },
      true
    )
  }

  // Prepare page title and headings
  const title = toTitleCase(
    type && !city & !area //How to check for other filters also
      ? `Kaikki ${type}`
      : `${type ? type : ""}${city ? " " + city : ""}${area ? " " + area : ""}`
  )

  const introText =
    R.path(["page", "introDescription", "introDescription"], data) ||
    getIntroText(type, city, area)
  const metaDescription =
    R.path(["page", "metaDescription", "metaDescription"], data) ||
    getMetaDesc(type, city, area) ||
    introText ||
    title
  const metaTitle =
    R.path(["page", "seoContentTitle"], data) ||
    getTitle(type, city, area) ||
    title

  /* For these content types on the page, check if anything specific has been defined or then just use the defaults */
  const heroImageFluid = R.path(["page", "headingImage", "gatsbyImageData"], data)
    ? R.path(["page", "headingImage", "gatsbyImageData"], data)
    : R.path(["defaultHero", "gatsbyImageData"], data)
  const featuredArticle = R.path(["page", "featuredArticle", "id"], data)
    ? R.path(["page", "featuredArticle"], data)
    : R.path(["defaultFeaturedArticle"], data)
  const featuredReferences = R.pathOr([], ["page", "featuredReferences"], data)
    .length
    ? R.path(["page", "featuredReferences"], data)
    : R.pathOr([], ["defaultReferences", "nodes"], data)

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Typography
          className={classes.contentHeadings}
          variant="h2"
          component="h2"
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Typography
          className={classes.contentHeadings}
          variant="h3"
          component="h3"
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <Typography
          className={classes.contentHeadings}
          variant="h4"
          component="h4"
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <Typography
          className={classes.contentHeadings}
          variant="h5"
          component="h5"
        >
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <Typography
          className={classes.contentHeadings}
          variant="h6"
          component="h6"
        >
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography
          className={classes.contentText}
          variant="body1"
          component="p"
        >
          {children}
        </Typography>
      ),

      [BLOCKS.EMBEDDED_ASSET]: node => {
        // For the logic behind getting this to work, see answers to
        // https://github.com/contentful/rich-text/issues/70
        const alt = R.path(
          ["data", "target", "fields", "description", "fi"],
          node
        )
        const assetId = R.path(["data", "target", "sys", "contentful_id"], node)
        const fluid = R.path([assetId, "gatsbyImageData"], embeddedImagesFluid)
        return fluid ? (
          <GatsbyImage className={classes.embeddedImage} image={fluid} alt={alt} />
        ) : null
      },
    },
  }

  const classes = useStyles()

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription}
        ogTitle={R.path(["page", "openGraphTitle"], data) || metaTitle}
        ogDescription={
          R.path(
            ["page", "openGraphDescription", "openGraphDescription"],
            data
          ) ||
          introText ||
          metaDescription
        }
        ogImg={
          R.path(["page", "openGraphImage", "file", "url"], data) ||
          R.path(["page", "headingImage", "file", "url"], data)
        }
      />

      <Hero headline={`${title}`} backgroundImageFluid={heroImageFluid} compact>
        {introText && (
          <Typography
            className={classes.introDescription}
            variant="body2"
            component="p"
          >
            {introText}
          </Typography>
        )}
        <SearchBox preloadIndex={true} />
      </Hero>

      <Container className={classes.resultsContainer}>
        <Breadcrumb type={type} city={city} area={area} />

        {dynamic ? (
          <React.Fragment>
            {/* Dynamic landing pages will have parsed the search criteria from the URL */}
            <Router>
              <Results path="*" location={location} />
            </Router>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {/* SSR landing pages are prebuilt by Gatsby for indexing by crawlers */}
            <Results location={location} />
          </React.Fragment>
        )}
      </Container>
      {[
          "/liiketilat/turku",
          "/varastot/jyvaskyla",
          "/toimitilat/vaasa",
          "/toimitilat/rovaniemi",
          "/toimitilat/lappeenranta"
        ].includes(currentUrl) && 
        R.path(["page", "content"], data) && (
        <Box className={classes.contentContainer}>
          <Container>
            <Typography
              className={classes.contentHeadings}
              variant="h3"
              component="h2"
            >
              {metaTitle}
            </Typography>
            <Typography variant="body1" component="div">
              {R.path(["page", "content"], data) && renderRichText(R.path(["page", "content"], data), options)}
            </Typography>
          </Container>
        </Box>
      )}

      {/* Infoballs */}
      {![
          "/liiketilat/helsinki",
          "/liiketilat/tampere",
          "/toimitilat/turku",
          "/toimitilat/tampere",
          "/varastot/oulu"
        ].includes(currentUrl) && 
        R.path(["contentfulEtusivu", "newsecInfoBalls"]) && 
        (
        <Profile
          heading={R.path(["contentfulEtusivu", "newsecInfoHeading"], data)}
          text={R.path(
            ["contentfulEtusivu", "newsecInfoText", "newsecInfoText"],
            data
          )}
          infoBalls={R.path(["contentfulEtusivu", "newsecInfoBalls"], data)}
        />
      )}

      {/* Newsec About & Link */}
      {![
          "/liiketilat/helsinki",
          "/liiketilat/tampere",
          "/toimitilat/turku",
          "/toimitilat/tampere",
          "/varastot/oulu"
        ].includes(currentUrl) && 
        <Highlight
          heading={R.path(["contentfulEtusivu", "newsecAboutHeading"], data)}
          lead={R.path(
            ["contentfulEtusivu", "newsecAboutText", "newsecAboutText"],
            data
          )}
          backgroundImageFluid={R.path(
            ["contentfulEtusivu", "newsecAboutBackgroundImage", "gatsbyImageData"],
            data
          )}
          link={R.path(["contentfulEtusivu", "newsecAboutLink"], data)}
        />
      }

      {/* Quotes */}
      {![
          "/liiketilat/helsinki",
          "/liiketilat/tampere",
          "/toimitilat/turku",
          "/toimitilat/tampere",
          "/varastot/oulu"
        ].includes(currentUrl) && 
        featuredReferences && 
        <References references={featuredReferences} />}

      {/* Featured Article */}
      {![
          "/liiketilat/helsinki",
          "/liiketilat/tampere",
          "/toimitilat/turku",
          "/toimitilat/tampere",
          "/varastot/oulu"
        ].includes(currentUrl) && 
        featuredArticle && 
        <FeaturedContent items={[featuredArticle]} />}

      {/* The background for the search page content needs set outside of the container */}
      {![
          "/liiketilat/turku",
          "/varastot/jyvaskyla",
          "/toimitilat/vaasa",
          "/toimitilat/rovaniemi",
          "/toimitilat/lappeenranta"
        ].includes(currentUrl) && 
        R.path(["page", "content"], data) && (
        <Box className={classes.contentContainer}>
          <Container>
            <Typography
              className={classes.contentHeadings}
              variant="h3"
              component="h2"
            >
              {metaTitle}
            </Typography>
            <Typography variant="body1" component="div">
              {R.path(["page", "content"], data) && renderRichText(R.path(["page", "content"], data), options)}
            </Typography>
          </Container>
        </Box>
      )}
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query searchPageQuery(
    $region: String
    $embeddedImages: [String!]!
    $defaultHeroImageId: String
    $defaultFeaturedArticleId: String
    $defaultReferenceIds: [String]
  ) {
    page: contentfulLandingPage(region: { eq: $region }) {
      region
      headingImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1200, 
          quality: 30,
        )
      }
      introDescription {
        introDescription
      }
      seoContentTitle
      content {
        raw
      }
      metaDescription {
        metaDescription
      }
      featuredReferences {
        name
        role
        workplace
        testimonial {
          testimonial
        }
      }
      featuredArticle {
        id
        slug
        title
        heading
        headingImage {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 400, 
            quality: 80,
          )
        }
        leadText {
          leadText
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      openGraphTitle
      openGraphDescription {
        openGraphDescription
      }
      openGraphImage {
        file {
          url
          contentType
        }
      }
    }
    embeddedImages: allContentfulAsset(
      filter: { contentful_id: { in: $embeddedImages } }
    ) {
      edges {
        node {
          contentful_id
          description
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 500, 
            quality: 90,
          )
        }
      }
    }
    defaultHero: contentfulAsset(id: { eq: $defaultHeroImageId }) {
      gatsbyImageData(
        formats: [AUTO, WEBP],
        layout: CONSTRAINED, 
        width: 800, 
        quality: 90,
      )
    }
    defaultFeaturedArticle: contentfulArticle(
      id: { eq: $defaultFeaturedArticleId }
    ) {
      id
      slug
      title
      heading
      headingImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 500, 
          quality: 80,
        )
      }
      leadText {
        leadText
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
    }
    defaultReferences: allContentfulTestimonial(
      filter: { id: { in: $defaultReferenceIds } }
    ) {
      nodes {
        name
        role
        workplace
        testimonial {
          testimonial
        }
      }
    }
    contentfulEtusivu(activeVersion: { eq: "Kyllä" }) {
      newsecInfoHeading
      newsecInfoText {
        newsecInfoText
      }
      newsecInfoBalls {
        metric
        description
        icon {
          gatsbyImageData(
            formats: [AUTO, WEBP],
            layout: CONSTRAINED, 
            width: 150, 
            quality: 80,
          )
        }
      }
      newsecAboutHeading
      newsecAboutText {
        newsecAboutText
      }
      newsecAboutLink
      newsecAboutBackgroundImage {
        gatsbyImageData(
          formats: [AUTO, WEBP],
          layout: CONSTRAINED, 
          width: 1000, 
          quality: 80,
        )
      }
    }
  }
`
